@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PhoenixSans-Regular";
  src: url("./assets/fonts/PhoenixSans-Regular.woff") format("woff"), url("./assets/fonts/PhoenixSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "PhoenixSans-Medium";
  src: url("./assets/fonts/PhoenixSans-Medium.woff") format("woff"), url("./assets/fonts/PhoenixSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "PhoenixSans-Bold";
  src: url("./assets/fonts/PhoenixSans-Bold.woff") format("woff"), url("./assets/fonts/PhoenixSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

#widgetEmbed {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background-color: #F9FAFB;
  font-family: "PhoenixSans-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  margin: 0;
}

a {
  @apply text-blue-600 hover:underline;
}

h1 {
  font-family: "PhoenixSans-Medium", sans-serif;
  @apply text-h1 font-medium mb-7;
}

.intro p {
  @apply text-pRegular sm:text-pLarge mb-4;
}

.agegroup-intro p{
  @apply border-b border-lightGrey text-pLarge pb-8 mb-8 mt-2
}

.accordion-header button {
  @apply flex justify-between xs:justify-start
}

.footer-accordion-header button {
  @apply flex justify-start
}

strong {
  @apply font-bold font-PhoenixSansBold
}

.intro ul {
  @apply list-disc list-inside text-offWhite text-pRegular sm:text-pLarge pl-4
}

.intro ul li:last-of-type {
 @apply mb-4
}